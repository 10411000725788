import * as React from "react"
import WithRoot from "../pages/withRoot"
import { graphql } from "gatsby"
import { createProgress } from "../graphql/mutations"
import { getVimeoId } from "../graphql/queries"
import Course from "../components/course"
import { API, graphqlOperation, Auth } from "aws-amplify"
import { navigate } from "gatsby"
import { getUserProgress, getCurrentUser, getNextVideo, getVideoSlug, appendUserProgress } from "../utils/modelService"

import videosData from "../content/videos.json"
import moduleData from "../content/modules.json"

const VideoTemplate = ({ data }) => {
  const [securedVimeoData, setSecuredVimeoData] = React.useState<string>()
  const [userProgress, setUserProgress] = React.useState<UserProgress[]>()
  const [isStudent, setIsStudent] = React.useState(undefined)
  const videoPageDatum = data as AllVideoData
  const currentVideoPage = videoPageDatum.allVideosJson.nodes[0]

  React.useEffect(() => {
    checkUserState()
  }, [])

  const checkUserState = async () => {
    const user = await getCurrentUser()
    if (!user) {
      setIsStudent(false)
    } else {
      const _userProgress = getUserProgress()
      setUserProgress(_userProgress)
      user.getSession((err, session) => {
        try {
          if (err) throw err
          const groups = session.getIdToken().payload["cognito:groups"]
          if (!!groups) {
            setIsStudent(groups.includes("Student"))
          } else {
            setIsStudent(false)
          }
        } catch (err) {
          console.error(err)
        }
      })
    }
    let vimeoID = ""
    if (currentVideoPage.isUploaded) {
      if (currentVideoPage.isFree && currentVideoPage.isUploaded && !!currentVideoPage.vimioId) {
        vimeoID = currentVideoPage.vimioId
      } else {
        const vimeoIDs = await getVimeoData()
        vimeoID = !!vimeoIDs ? vimeoIDs.data.getVimeoId.vimeoId : ""
      }
    }
    setSecuredVimeoData(vimeoID)
  }

  const getVimeoData = async () => {
    try {
      const vidIds = await API.graphql(graphqlOperation(getVimeoId, { id: currentVideoPage.vimeoLookUp }))
      return vidIds
    } catch (err) {
      console.error(err)
    }
  }

  const moduleTree = () => {
    return moduleData.map(module => {
      const videos = videosData.filter(vid => vid.moduleId === module.id)
      return {
        ...module,
        videos
      }
    })
  }

  const onVideoComplete = async () => {
    if (!!userProgress) {
      var existingProgress = userProgress.find(
        x =>
          x.moduleId == currentVideoPage.moduleId &&
          x.videoOrder == currentVideoPage.videoOrder &&
          x.videoName == currentVideoPage.title
      )
      if (!existingProgress) {
        try {
          appendUserProgress({
            moduleId: currentVideoPage.moduleId,
            videoOrder: currentVideoPage.videoOrder,
            videoName: currentVideoPage.title,
            slug: "/" + currentVideoPage.fields.slug
          })
          // const result = await API.graphql(
          //   graphqlOperation(createProgress, {
          //     input: {
          //       moduleId: currentVideoPage.moduleId,
          //       videoOrder: currentVideoPage.videoOrder,
          //       videoName: currentVideoPage.title,
          //       slug: "/" + currentVideoPage.fields.slug
          //     }
          //   })
          // )
          //console.log(result)
        } catch (err) {
          console.error(err)
        }
      }
    }

    const nextPage = getNextVideo(currentVideoPage.moduleId, currentVideoPage.videoOrder)
    const nextSlug = getVideoSlug(nextPage.moduleId, nextPage.title)
    navigate("/" + nextSlug)
  }

  return (
    <Course
      videoData={videoPageDatum}
      vimeoID={securedVimeoData}
      isStudent={isStudent}
      moduleTree={moduleTree()}
      userProgress={userProgress}
      onVideoComplete={onVideoComplete}
    />
  )
}

export const query = graphql`
  query VideoTemplateQuery($slug: String!) {
    allVideosJson(filter: { fields: { slug: { eq: $slug } } }) {
      nodes {
        fields {
          slug
        }
        description
        isFree
        isUploaded
        links {
          title
          url
        }
        moduleId
        videoOrder
        repoLink
        title
        vimioId
        vimeoLookUp
        duration
        mdLookUp {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allModulesJson {
      nodes {
        title
        id
      }
    }
  }
`

export default WithRoot(VideoTemplate)
