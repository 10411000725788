import React from "react"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import MenuIcon from "@material-ui/icons/Menu"
import Typography from "@material-ui/core/Typography"
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"

import Player from "@vimeo/player"
import VideoIndex from "./videoIndex"
import UnderConstruction from "./underConstruction"
import BecomeAStudent from "./becomeAStudent"
import Supplemental from "./SupplimentalImformation"
import { CircularProgress, Grid } from "@material-ui/core"

const drawerWidth = 300

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    menuButton: {
      // marginRight: theme.spacing(2),
      // [theme.breakpoints.up("sm")]: {
      //   display: "none"
      // }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    videoIframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    videoContainer: {
      padding: "55% 0 0 0",
      position: "relative"
    },

    progress: {
      display: "flex"
    },
    progressContain: {
      display: "flex",

      height: "70vh",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      margin: "20px"
    }
  })

interface CourseIndexProps extends WithStyles<typeof styles> {
  videoData: AllVideoData
  vimeoID: string
  isStudent: boolean
  container?: Element
  moduleTree: ModuleTree[]
  userProgress: UserProgress[]
  onVideoComplete: () => void
}

function CourseIndex(props: CourseIndexProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [videoLoaded, setVideoLoaded] = React.useState(false)

  React.useEffect(() => {
    let playerIframe = document.getElementById("vimeo-player")
    if (!!playerIframe) {
      let player = new Player("vimeo-player")
      player.on("ended", () => {
        props.onVideoComplete()
      })
      player.on("loaded", () => {
        setVideoLoaded(true)
      })
    }
    // sometimes videos that take a long time to load don't get a loaded event
    // even though they do load so set a time out
    setTimeout(() => setVideoLoaded(true), 2000)
  }, [props.vimeoID])

  const { classes, container, ...other } = props
  const currentVideoPage = props.videoData.allVideosJson.nodes[0]

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const shouldShowSpinner = () => {
    if (videoLoaded) return false
    if (!currentVideoPage.isUploaded) return false
    if (!props.isStudent && !props.vimeoID && !!currentVideoPage.isUploaded) return false
    return true
  }

  const ShouldRenderContent = () => {
    if (currentVideoPage.isUploaded) {
      if (currentVideoPage.isFree) return true
      if (props.isStudent) return true
    }
    return false
  }

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={"left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <VideoIndex {...other} isMobile={true} contentLoaded={!shouldShowSpinner()} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
            anchor={"left"}
          >
            <VideoIndex {...other} isMobile={false} contentLoaded={!shouldShowSpinner()} />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div
          className={
            ""
            //classes.toolbar
          }
        />
        <Hidden smUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        {!currentVideoPage.isUploaded && <UnderConstruction />}
        {props.isStudent === false && !props.vimeoID && !!currentVideoPage.isUploaded && <BecomeAStudent />}
        {shouldShowSpinner() && (
          <div className={classes.progressContain}>
            <div className={classes.progress}>
              <CircularProgress size={150} />
            </div>
          </div>
        )}
        {!!props.vimeoID && ShouldRenderContent() && (
          <div className={classes.videoContainer}>
            <iframe
              id="vimeo-player"
              src={`https://player.vimeo.com/video/${props.vimeoID}?&title=0&byline=0&portrait=0`}
              className={classes.videoIframe}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        )}
        {ShouldRenderContent() && <Supplemental videoData={currentVideoPage} />}
      </main>
    </div>
  )
}

export default withStyles(styles)(CourseIndex)
