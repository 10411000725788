import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CardActions,
  Grid
} from "@material-ui/core"
import { Link, navigate } from "gatsby"
import becomeAStudent from "../../assets/undraw/undraw_teaching_f1cm.svg"

const styles = (theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 345
    },
    media: {
      height: 140
    }
  })

interface UnderConstructionProps extends WithStyles<typeof styles> {}

const UnderConstruction = (props: UnderConstructionProps) => {
  const { classes } = props
  return (
    <Grid container justify="center">
      <Grid item>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.media} image={becomeAStudent} title="Coming Soon" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Register as a student
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                You need to be registered as a student to view this content.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button color="primary" onClick={() => navigate("/stripe-checkout")}>
              Register now
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}
export default withStyles(styles)(UnderConstruction)
