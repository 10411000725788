import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Divider,
  //ExpansionPanel,
  Accordion,
  AccordionDetails,
  //ExpansionPanelSummary,
  AccordionSummary,
  Typography,
  //ExpansionPanelDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core"
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { getVideoSlug } from "../../utils/modelService"
import { navigate } from "gatsby"

var pageLoadedCount = 0

const styles = (theme: Theme) =>
  createStyles({
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
      minHeight: 0
    },
    notUploaded: {
      fontStyle: "oblique",
      color: theme.palette.grey.A100
    },
    heading: {
      fontSize: theme.typography.pxToRem(15)
    },
    currentVid: {
      color: "blue"
    },
    currentVidNotUploaded: {
      color: "lightBlue"
    },
    watchedVid: {
      color: "green"
    },
    unWatchedVid: {
      color: "#FFC071"
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.secondary,
      display: "block"
    },
    free: {
      color: "green",
      marginLeft: theme.spacing(1),
      fontStyle: "oblique"
    }
  })

interface VideoIndexProps extends WithStyles<typeof styles> {
  moduleTree: ModuleTree[]
  videoData: AllVideoData
  userProgress: UserProgress[]
  isMobile: boolean
  contentLoaded: boolean
}

const VideoIndex = (props: VideoIndexProps) => {
  const [expandedModule, setExpandedModule] = React.useState<string>()

  const currentGQLVid = props.videoData.allVideosJson.nodes[0]

  React.useEffect(() => {
    const currentModule = currentGQLVid.moduleId
    setExpandedModule(currentModule.toString())
  }, [])

  React.useEffect(() => {
    if (!props.contentLoaded) return
    if (props.isMobile) return
    const id = `vid-Id_${currentGQLVid.moduleId}_${currentGQLVid.videoOrder}`
    var el = document.getElementById(id)
    if (!el) return
    const pos = offset(el)
    if (!pos.top) return
    let scrollPos = pos.top
    if (scrollPos >= 64) scrollPos = scrollPos - 64
    if (scrollPos === 0) return
    const drawerEl = document.getElementById("index-container").parentElement
    if (!drawerEl) return
    drawerEl.scroll(0, scrollPos)
  }, [props.contentLoaded])

  function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  const { classes } = props

  const getProgressClass = (video: Video) => {
    if (video.moduleId === currentGQLVid.moduleId && video.videoOrder == currentGQLVid.videoOrder)
      return video.isUploaded ? classes.currentVid : classes.currentVidNotUploaded

    if (!!props.userProgress) {
      if (props.userProgress.findIndex(up => up.moduleId === video.moduleId && up.videoOrder === video.videoOrder) >= 0)
        return classes.watchedVid
    }
    return !video.isUploaded ? "" : classes.unWatchedVid
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpandedModule(isExpanded ? panel : null)
  }

  const navigateToVid = (video: Video) => {
    const slug = getVideoSlug(video.moduleId, video.title)
    navigate("/" + slug)
  }

  const getTime = time => {
    var minutes = Math.floor(time / 60)
    var seconds = time - minutes * 60
    return {
      minutes,
      seconds
    }
  }

  const timeAsStr = duration => {
    var minSecs = getTime(duration)
    return `${minSecs.minutes}:${minSecs.seconds}`
  }

  const moduleTime = (moduleSegment: ModuleTree) => {
    const totalSeconds = moduleSegment.videos.reduce((prev, curr) => curr.duration + prev, 0)
    return timeAsStr(totalSeconds)
  }

  return (
    <div id="index-container">
      <div className={classes.drawerHeader}>
        {/* <IconButton onClick={handleDrawerToggle}>
      {theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton> */}
      </div>
      <Divider />

      {props.moduleTree.map((module, modIndex) => (
        <Accordion
          key={module.id}
          expanded={expandedModule === module.id.toString()}
          onChange={handleChange(module.id.toString())}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography className={classes.heading}>
              {module.title}
              <span className={classes.secondaryHeading}> {moduleTime(module)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedModule === module.id.toString() && (
              <List dense={true}>
                {module.videos.map((video, vidIndex) => {
                  return (
                    <ListItem
                      key={`vid_${modIndex + 1}_${vidIndex + 1}`}
                      className={`${!video.isUploaded ? classes.notUploaded : ""}`}
                      button
                      onClick={() => navigateToVid(video)}
                      id={`vid-Id_${modIndex + 1}_${vidIndex + 1}`}
                    >
                      <ListItemIcon>
                        <PlayCircleOutlineIcon className={getProgressClass(video)} />
                      </ListItemIcon>
                      <ListItemText
                        primary={video.title}
                        secondary={
                          <>
                            <span>{timeAsStr(video.duration)}</span>
                            {video.isFree && <span className={classes.free}>free</span>}
                          </>
                        }
                      />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
export default withStyles(styles)(VideoIndex)
