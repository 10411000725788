// tslint:disable
// this is an auto generated file. This will be overwritten

export const getVimeoId = `query GetVimeoId($id: ID!) {
  getVimeoId(id: $id) {
    id
    videoName
    vimeoId
  }
}
`;
export const listVimeoIds = `query ListVimeoIds(
  $filter: ModelVimeoIdFilterInput
  $limit: Int
  $nextToken: String
) {
  listVimeoIds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      videoName
      vimeoId
    }
    nextToken
  }
}
`;
export const getProgress = `query GetProgress($id: ID!) {
  getProgress(id: $id) {
    id
    moduleId
    videoOrder
    videoName
    slug
    owner
  }
}
`;
export const listProgresss = `query ListProgresss(
  $filter: ModelProgressFilterInput
  $limit: Int
  $nextToken: String
) {
  listProgresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      moduleId
      videoOrder
      videoName
      slug
      owner
    }
    nextToken
  }
}
`;
