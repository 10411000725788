import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CardActions,
  Grid
} from "@material-ui/core"
import underConstruction from "../../assets/under-construction.png"

const styles = (theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 345
    },
    media: {
      height: 240
    }
  })

interface UnderConstructionProps extends WithStyles<typeof styles> {}

const UnderConstruction = (props: UnderConstructionProps) => {
  const { classes } = props
  return (
    <Grid container justify="center">
      <Grid item>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.media} image={underConstruction} title="Coming Soon" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Coming Soon
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                This video is under construction. We will send out an update once complete. If you have any questions
                please Contact us
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions></CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}
export default withStyles(styles)(UnderConstruction)
