import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography
} from "@material-ui/core"
import LinkIcon from "@material-ui/icons/Link"
import WorkIcon from "@material-ui/icons/Work"
import BeachAccessIcon from "@material-ui/icons/BeachAccess"
import GitIcon from "../../assets/icons/GitHub-Mark/GitHub-Mark-32px.png"
import "prismjs/themes/prism.css"
import "./SupplimentalImformation.css"

const styles = (theme: Theme) =>
  createStyles({
    root: {}
  })

interface SupplementalProps extends WithStyles<typeof styles> {
  videoData: VideoNode
}

const Supplemental = (props: SupplementalProps) => {
  const { classes } = props
  return (
    <div>
      <List>
        {props.videoData.repoLink && (
          <>
            <ListItem button component="a" rel="noopener noreferrer" target="_blank" href={props.videoData.repoLink}>
              <ListItemAvatar>
                <Avatar>
                  <img src={GitIcon} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Source On Git" />
            </ListItem>
          </>
        )}
        {props.videoData.links.map(link => (
          <ListItem key={link.url} button component="a" rel="noopener noreferrer" target="_blank" href={link.url}>
            <ListItemAvatar>
              <Avatar>
                <LinkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={link.title} />
          </ListItem>
        ))}
      </List>

      {!!props.videoData.mdLookUp && (
        <div
          className="supplement-md"
          dangerouslySetInnerHTML={{ __html: props.videoData.mdLookUp.childMarkdownRemark.html }}
        />
      )}
    </div>
  )
}
export default withStyles(styles)(Supplemental)
